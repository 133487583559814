import React, { useState } from "react"
import styles from "../../../styles/solutions.module.scss"
import cblockStyles from "../../../styles/commons/cblock.module.scss"
import FsLightbox from "fslightbox-react"
import Layout from "../../../components/layout/layout"
import StartDemo from "../../../components/start-demo/start-demo"
import SEO from "../../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import withAppContext from "../../../contexts/app.context"
import UseCaseOverview from "../../../components/use-case-overview/use-case-overview"
import { Helper } from "../../../utils/helper"
import { ENV_CONSOLE_BASE_URL } from "../../../utils/secrets"
import McnHeroAnim from "../../../components/animated-graphs/mcn-hero/mcn-hero-anim"
import TopFeatures from "../../../components/top-features/top-features"
import ComparisonTable from "../../../components/comparison-table/comparison-table"
import CascadeVideo from "../../../components/cascade-video/cascade-video"
import PlaySvg from "../../../components/svg/play"
import ArrowSvg from "../../../components/svg/arrow"

const NetworkingAndSecurityPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "solutions__multi-cloud-networking" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const demoLink = "https://player.vimeo.com/video/426635097?autoplay=1"
  const overviewLink = "https://player.vimeo.com/video/409046538?autoplay=1"

  const [isVideoOpened, setVideoOpened] = useState(false)
  const [currentVideoUrl, setCurrentVideoUrl] = useState(overviewLink)

  const openSelectedVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(overviewLink)
    setVideoOpened(!isVideoOpened)
  }

  const openDemoVideo = () => {
    Helper.triggerGtagVideo(currentVideoUrl)
    setCurrentVideoUrl(demoLink)
    setVideoOpened(!isVideoOpened)
  }

  const [selectedTopFeatIndex, setselectedTopFeatIndex] = useState(1)

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/solutions/multi-cloud/multi-cloud-networking"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={styles.header}>
        <div className={styles.header__content}>
          <div className="container">
            <div className="row">
              <div className={`col-lg-7`}>
                <h1 className={styles.header__title}>Multi-Cloud Networking</h1>
                <p className={styles.header__description}>
                  A revolutionary networking and security service for public
                  and/or private clouds - relieving NetOps and DevOps teams from
                  the struggle of managing multiple services and lack of
                  end-to-end policy + observability.
                </p>
                <div className={styles.header__cta}>
                  <a
                    aria-label="Start for Free"
                    href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                    className={"btn btn-primary " + styles.header__btn}>
                    Start for Free
                  </a>
                  <button
                    onClick={openDemoVideo}
                    className={`btn btn-primary ml-4 ${styles.header__btn} ${styles.header__btnPlay}`}>
                    <PlaySvg />
                    Watch Demo
                  </button>
                </div>
                <div className={styles.header__doc}>
                  <p>
                    <a
                      aria-label="View demo documentation"
                      className={styles.header__link}
                      href="/docs/quick-start/multi-cloud-networking">
                      View demo documentation
                      <ArrowSvg />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.header__imgwrapper}>
            <button
              className={styles.header__imgbtn}
              onClick={openSelectedVideo}>
              <PlaySvg />
            </button>
            <McnHeroAnim />
          </div>
        </div>
      </section>

      {/* Benefits */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Benefits of VoltMesh for <br />
              Multi-Cloud Networking
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <TopFeatures
              props={{
                features: [
                  {
                    title: "Significantly faster deployments",
                    desc:
                      "Accelerate cloud migration or adoption of a new cloud provider using a consolidated service that exposes the same API and networking + security capabilities across any cloud provider.",
                    imgUrl: "/img/solutions/faster-deployments.svg",
                    imgAlt: "Cloud deployments illustration",
                  },
                  {
                    title: "Extreme productivity gains",
                    desc:
                      "NetOps can speed up migration to infra-as-code with built-in automation assistance and lifecycle management. They can improve collaboration by delivering self-service to their DevOps and SecOps teams.",
                    imgUrl: "/img/solutions/extreme-productivity-gains.svg",
                    imgAlt: "Network operations illustration",
                  },
                  {
                    title: "Simplify infrastructure operations",
                    desc:
                      "SaaS-managed VoltMesh nodes in your cloud VPC with the option to use our global network helps you deliver a secure multi-cloud network without worrying about complex network ops.",
                    imgUrl: "/img/solutions/simplify-infra-ops.svg",
                    imgAlt: "Network deployment illustration",
                  },
                  {
                    title: "Significantly better functionality",
                    desc:
                      "NetOps deploy VoltMesh in their services VPC and configure networking + security. DevOps configure DNS, load balancing, API gateway on the same deployment. Your global, private, and service rich network is available within minutes. ",
                    imgUrl: "/img/solutions/better-functionality.svg",
                    imgAlt: "Global network in minutes illustration",
                  },
                  {
                    title: "Architecture & engineering support",
                    desc:
                      "Our solutions engineering team is available to help your NetOps and DevOps team design, implement, and test the most optimal and secure network within cloud or across multi/hybrid-cloud.",
                    imgUrl:
                      "/img/solutions/architecture-engineering-support.svg",
                    imgAlt: "Cloud support illustration",
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Comparison Table */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              VoltMesh vs. Other Multi-Cloud Solutions
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <ComparisonTable
              props={{
                cols: ["Features", "Other Solutions", "VoltMesh"],
                rows: [
                  {
                    name: "Consolidated L3-L7+ networking + security service",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Multi-tenancy + self-service for NetOps & DevOps",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Multi-layer security",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Global physical network",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Automation assistance for NetOps",
                    cells: ["Included", "Included"],
                  },
                  {
                    name: "Observability and analytics",
                    cells: ["External", "Included"],
                  },
                  {
                    name: "Lifecycle management",
                    cells: ["Controller", "SaaS"],
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Solution Overview */}
      <UseCaseOverview
        props={{
          content: {
            description: `
            VoltMesh delivers consolidated networking, security, and load balancing/ADC in any cloud. It also automates the configuration of cloud resources like AWS transit gateway, Azure and GCP networking to reduce automation burden on NetOps. Multi-tenancy of the platform enables NetOps to deliver <nobr>self-service</nobr> to DevOps and reduce the need to manage additional services per app cluster. Optionally, the customer may rely on Volterra’s global network to provide secure and high performance connectivity across VoltMesh nodes deployed in multiple cloud providers, data centers, or enterprise edge locations.`,
            diagram: {
              alt: "Network & Security diagram",
              name: "network-and-security-diagram.png",
              width: 840,
              md: {
                name: "network-and-security-diagram-md.png",
                width: 760,
              },
              sm: {
                name: "network-and-security-diagram-md.png",
                width: 460,
              },
            },

            capabilities: {
              left: [
                "Secure networking",
                "Application and network security",
                "Unified policy",
                "Load balancer and service mesh",
                "Global network with secure backbone",
                "Cross-cloud observability",
              ],
            },
          },
        }}
      />

      {/* Ready in minutes */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__vmtlmr} ${cblockStyles.cblock__grey}`}>
        <div className={"container"}>
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Production Multi-Cloud Network within Minutes
            </h2>
          </div>

          <div className={cblockStyles.cblock__body}>
            <div className={"row " + styles.topfeatsslider}>
              <div className="col-lg-5">
                <div className={styles.topfeatsslider__items}>
                  {/* Item 1 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(1)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 1
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>1</span>
                      <h5>
                        Deploy VoltMesh in Public&nbsp;
                        <span>
                          Cloud
                          <img
                            className={`ml-2 ${styles.topfeatsslider__caret}`}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Fully automated provisioning of VoltMesh in AWS using
                        the UI or our vesctl CLI - pick the AWS region and AZ
                        and let Volterra configure everything else
                      </p>
                      <a
                        href="/docs/quick-start/multi-cloud-networking#step-1-deploy-site-public-cloud"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 1: Deploy Site (Public Cloud)">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 1 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462859627.sd.mp4?s=e6442ea1c6db1c6aafe437791fe5e8bdee00908e&profile_id=165",
                                  videoLength: "37000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462876969.sd.mp4?s=22e0ef5e94360c218da26fafa10fa0e631ff5355&profile_id=165",
                                  videoLength: "16000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 2 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(2)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 2
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>2</span>
                      <h5>
                        Deploy VoltMesh in Private&nbsp;
                        <span>
                          Cloud
                          <img
                            className={styles.topfeatsslider__caret}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Fully automated provisioning of VoltMesh in VMware
                        cluster using vSphere UI or using Terraform
                      </p>
                      <a
                        href="/docs/quick-start/multi-cloud-networking#step-2-deploy-site-private-dc"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 2: Deploy Site (Private DC)">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 2 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462859649.sd.mp4?s=6c9cc9ae4451cfb4939536c2b59a60d8ec46fb31&profile_id=165",
                                  videoLength: "68000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462881043.sd.mp4?s=62ab6c117aec53d763783f4e2c494954fd828b1d&profile_id=165",
                                  videoLength: "29000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 3 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(3)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 3
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>3</span>
                      <h5>
                        Multi-cloud Network +&nbsp;
                        <span>
                          Security
                          <img
                            className={`ml-2 ${styles.topfeatsslider__caret}`}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Configure global network to connect the two sites along
                        with network and L7 policies to restrict access to
                        resources across sites and the internet
                      </p>
                      <a
                        href="/docs/quick-start/multi-cloud-networking#step-3-connect-networks"
                        className={styles.topfeatsslider__link}
                        target="_blank"
                        aria-label="Step 3: Connect Networks">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 3 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462859707.sd.mp4?s=032b1e0d7483e5726df48ecaacb70325358124f9&profile_id=165",
                                  videoLength: "58000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462881119.sd.mp4?s=1dd166625c8cdcfc0959ac13854445a62ae8e4ee&profile_id=165",
                                  videoLength: "12000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 4 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(4)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 4
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>4</span>
                      <h5>
                        Access your Secure&nbsp;
                        <span>
                          Network
                          <img
                            className={styles.topfeatsslider__caret}
                            src="/img/products/caret-icon.svg"
                            alt=">"
                          />
                        </span>
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Send requests to services within the cloud, across the
                        cloud, or to the public internet - with secure and high
                        performance connectivity
                      </p>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 4 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462859747.sd.mp4?s=1563f9e86326667fc69e2aa01fcbaada0ea014f9&profile_id=165",
                                  videoLength: "11000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/462881179.sd.mp4?s=c2a4cda1282fffb80b4305948fa0e61ca4a380fe&profile_id=165",
                                  videoLength: "10000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className={styles.topfeatsslider__img}>
                  {selectedTopFeatIndex === 1 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462859627.sd.mp4?s=e6442ea1c6db1c6aafe437791fe5e8bdee00908e&profile_id=165",
                              videoLength: "37000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462876969.sd.mp4?s=22e0ef5e94360c218da26fafa10fa0e631ff5355&profile_id=165",
                              videoLength: "16000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 2 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462859649.sd.mp4?s=6c9cc9ae4451cfb4939536c2b59a60d8ec46fb31&profile_id=165",
                              videoLength: "68000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462881043.sd.mp4?s=62ab6c117aec53d763783f4e2c494954fd828b1d&profile_id=165",
                              videoLength: "29000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 3 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462859707.sd.mp4?s=032b1e0d7483e5726df48ecaacb70325358124f9&profile_id=165",
                              videoLength: "58000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462881119.sd.mp4?s=1dd166625c8cdcfc0959ac13854445a62ae8e4ee&profile_id=165",
                              videoLength: "12000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 4 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/462859747.sd.mp4?s=1563f9e86326667fc69e2aa01fcbaada0ea014f9&profile_id=165",
                              videoLength: "11000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/462881179.sd.mp4?s=c2a4cda1282fffb80b4305948fa0e61ca4a380fe&profile_id=165",
                              videoLength: "10000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <StartDemo
        props={{
          benefits: [
            "Multi-cloud networking",
            "Full network automation",
            "Global private network",
            "NetOps+DevOps collaboration",
          ],
        }}
      />

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            title="Video player"
            src={currentVideoUrl}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />
    </Layout>
  )
}

export default withAppContext(NetworkingAndSecurityPage)
