import React, { useEffect } from "react"
import styles from "./mcn-hero-anim.module.scss"
import anime from "animejs"

const McnHeroAnim = () => {
  const totalDuration = 2000
  const inputDuration = 2000
  const innerDuration = 3000
  const userDuration = 2500
  const azureDuration = 2500

  let maintl
  let inputTr
  let inner1Tr
  let inner2Tr
  let innerRev1Tr
  let innerRev2Tr
  let userTr
  let azureTr

  useEffect(() => {
    maintl = anime.timeline({
      easing: "easeInOutQuad",
      duration: totalDuration,
      autoplay: true,
    })

    inputTr = anime.timeline({
      easing: "linear",
      duration: inputDuration,
      autoplay: true,
      loop: true,
    })

    inner1Tr = anime.timeline({
      easing: "linear",
      duration: innerDuration,
      autoplay: true,
      loop: true,
    })

    inner2Tr = anime.timeline({
      easing: "linear",
      duration: innerDuration,
      autoplay: true,
      loop: true,
    })

    innerRev1Tr = anime.timeline({
      easing: "linear",
      duration: innerDuration,
      autoplay: true,
      loop: true,
    })

    innerRev2Tr = anime.timeline({
      easing: "linear",
      duration: innerDuration,
      autoplay: true,
      loop: true,
    })

    userTr = anime.timeline({
      easing: "linear",
      duration: userDuration,
      autoplay: true,
      loop: true,
    })

    azureTr = anime.timeline({
      easing: "linear",
      duration: azureDuration,
      autoplay: true,
      loop: true,
    })

    maintl
      .add({
        targets: "#img",
        duration: 900,
        opacity: [0, 1],
        translateY: [-8, 1],
      })
      .add({
        targets: "#svg",
        duration: 900,
        opacity: [0, 1],
      })

    inputTr
      .add({
        targets: "#bars-input1-1 g",
        duration: inputDuration,
        translateX: [81.46, 151.46],
        translateY: [0.11, -37.89],
      })
      .add({
        targets: "#bars-input1-2 g",
        duration: inputDuration,
        translateX: [0.46, 83.46],
        translateY: [36.11, 6.11],
        delay: -inputDuration,
      })

    inner1Tr
      .add({
        targets: "#bars-inner1-1 > g",
        duration: innerDuration,
        translateX: [-16.54, 43.46],
        translateY: [-7.89, -50.89],
      })
      .add({
        targets: "#bars-inner1-2 > g",
        duration: innerDuration,
        translateX: [-97.54, -16.54],
        translateY: [28.11, -2.89],
        delay: -innerDuration,
      })

    inner2Tr
      .add({
        targets: "#bars-inner2-1 > g",
        duration: innerDuration,
        translateX: [-16.54, 53.46],
        translateY: [-7.89, -46.89],
      })
      .add({
        targets: "#bars-inner2-2 > g",
        duration: innerDuration,
        translateX: [-97.54, -16.54],
        translateY: [28.11, -3.89],
        delay: -innerDuration,
      })

    innerRev1Tr
      .add({
        targets: "#bars-inner1rev-1 > g",
        duration: innerDuration,
        translateX: [0.46, -69.53],
        translateY: [43.11, 81.11],
      })
      .add({
        targets: "#bars-inner1rev-2 > g",
        duration: innerDuration,
        translateX: [68.46, 0.46],
        translateY: [0.11, 38.11],
        delay: -innerDuration,
      })

    innerRev2Tr
      .add({
        targets: "#bars-inner2rev-1 > g",
        duration: innerDuration,
        translateX: [-16.54, -86.54],
        translateY: [-7.89, 31.11],
      })
      .add({
        targets: "#bars-inner2rev-2 > g",
        duration: innerDuration,
        translateX: [48.46, -16.54],
        translateY: [-51.89, -11.89],
        delay: -innerDuration,
      })

    userTr
      .add({
        targets: "#bars-user1 > g",
        duration: userDuration,
        translateX: [-13.0, -80],
        translateY: [-5.746598, -37.75],
      })
      .add({
        targets: "#bars-user2 > g",
        duration: userDuration,
        translateX: [52.0, -13.0],
        translateY: [27.253402, -5.746598],
        delay: -userDuration,
      })

    azureTr
      .add({
        targets: "#bars-azure1-1 g",
        duration: azureDuration,
        translateX: [-12.0, -52.0],
        translateY: [-6.98, -33.98],
      })
      .add({
        targets: "#bars-azure1-2 g",
        duration: userDuration,
        translateX: [32.0, -12.0],
        translateY: [17.02, -6.98],
        delay: -azureDuration,
      })
      .add({
        targets: "#bars-azure2-1 g",
        duration: azureDuration,
        translateX: [0, -38],
        translateY: [25.019183, 56.2],
        delay: -azureDuration,
      })
      .add({
        targets: "#bars-azure2-2 g",
        duration: userDuration,
        translateX: [48.0, 0],
        translateY: [0.435, 25.019183],
        delay: -azureDuration,
      })
  })

  return (
    <div className={styles.vsanim}>
      <svg
        width="1029px"
        height="591px"
        viewBox="0 0 1029 591"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        id="svg">
        <defs>
          <polygon
            id="path-1"
            points="164.005229 8.34749446 184.737333 21.3975313 120.716844 56.6619081 98 44.1121791"></polygon>
          <polygon
            id="path-3"
            points="66.0052287 0.347494464 86.7373326 13.3975313 22.7168445 48.6619081 5.13280814e-15 36.1121791"></polygon>
          <polygon
            id="path-5"
            points="66.0052287 0.347494464 86.7373326 13.3975313 22.7168445 48.6619081 5.13280814e-15 36.1121791"></polygon>
          <polygon
            id="path-7"
            points="66.0052287 0.347494464 86.7373326 13.3975313 22.7168445 48.6619081 5.13280814e-15 36.1121791"></polygon>
          <polygon
            id="path-9"
            points="66.0052287 0.347494464 86.7373326 13.3975313 22.7168445 48.6619081 5.13280814e-15 36.1121791"></polygon>
          <polygon
            id="path-11"
            points="0 3.86964726 39.3349631 26.8942315 47 22.0191829 7.14597423 0"></polygon>
          <polygon
            id="path-13"
            points="13.866041 56.3643427 20 59.8406138 63 35.7021878 56.2630337 32"></polygon>
          <polygon
            id="path-15"
            points="13 10.1705215 73.3350838 42.2342187 81 38.0175258 19.4644507 6"></polygon>
          <linearGradient
            x1="50%"
            y1="38.9042307%"
            x2="50%"
            y2="100%"
            id="linearGradient-17">
            <stop
              stopColor="#FFFFFF"
              stopOpacity="0.143575175"
              offset="0%"></stop>
            <stop stopColor="#FFFFFF" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd">
          <g id="mcn" transform="translate(-235.000000, -1108.000000)">
            <g id="Group-4-Copy" transform="translate(235.000000, 1108.000000)">
              <path
                d="M613.43595,0.603577707 C619.783182,0.603577707 626.133649,1.99973195 630.977574,4.81228802 L630.977574,4.81228802 L1021.33526,226.791663 C1025.9946,229.497562 1028.39642,233.019595 1028.39642,236.581628 C1028.39642,240.14361 1025.99463,243.664921 1021.3383,246.367779 L1021.3383,246.367779 L433.900791,582.971959 C424.389328,588.496523 417.385789,590.763264 410.504306,590.348578 C402.845298,589.887036 395.353507,586.124393 384.763004,579.973096 L384.763004,579.973096 L7.66263188,363.215184 C3.00466022,360.510626 0.603577707,356.989283 0.603577707,353.427307 C0.603577707,349.865278 3.00468967,346.343214 7.66082147,343.638415 L7.66082147,343.638415 L595.899042,4.80957855 C600.738252,1.99973195 607.088719,0.603577707 613.43595,0.603577707 Z"
                id="-"
                strokeOpacity="0"
                stroke="#000000"
                strokeWidth="1.20715541"
                opacity="0.300000012"></path>
              <g
                id="input1"
                opacity="0.5"
                transform="translate(114.000000, 366.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g id="input1-mask"></g>
                <g id="bars-input1-2" mask="url(#mask-2)" fill="#FFFFFF">
                  <g transform="translate(0.464451, 36.112179)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 10.2511783 109.417874 39.3267215 121.350779 36.699592 65.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-32"
                      points="69.2946236 4.25117832 117.290733 31.6079465 129.223638 28.9808171 77.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 40.2511783 52.4178742 70.3267215 64.3507792 66.699592 8.65661632 36"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 34.2511783 63.4178742 63.3267215 75.3507792 60.699592 19.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 28.2511783 74.4178742 57.3267215 86.3507792 54.699592 30.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 22.2511783 86.4178742 51.3267215 98.3507792 48.699592 42.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 16.2511783 97.4178742 45.3267215 109.350779 42.699592 53.6566163 12"></polygon>
                  </g>
                </g>
                <g id="bars-input1-1" mask="url(#mask-2)" fill="#FFFFFF">
                  <g transform="translate(81.464451, 0.112179)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 4.25117832 109.417874 33.3267215 121.350779 30.699592 65.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 34.2511783 52.4178742 64.3267215 64.3507792 60.699592 8.65661632 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 28.2511783 63.4178742 57.3267215 75.3507792 54.699592 19.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 22.2511783 74.4178742 51.3267215 86.3507792 48.699592 30.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 16.2511783 86.4178742 45.3267215 98.3507792 42.699592 42.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 10.2511783 97.4178742 39.3267215 109.350779 36.699592 53.6566163 6"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="inner1"
                opacity="0.5"
                transform="translate(352.000000, 288.000000)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3"></use>
                </mask>
                <g id="inner1-mask"></g>
                <g id="bars-inner1-2" mask="url(#mask-4)" fill="#FFFFFF">
                  <g transform="translate(-97.535549, 28.112179)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 10.2511783 109.417874 39.3267215 121.350779 36.699592 65.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-32"
                      points="69.2946236 4.25117832 117.290733 31.6079465 129.223638 28.9808171 77.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 40.2511783 52.4178742 70.3267215 64.3507792 66.699592 8.65661632 36"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 34.2511783 63.4178742 63.3267215 75.3507792 60.699592 19.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 28.2511783 74.4178742 57.3267215 86.3507792 54.699592 30.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 22.2511783 86.4178742 51.3267215 98.3507792 48.699592 42.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 16.2511783 97.4178742 45.3267215 109.350779 42.699592 53.6566163 12"></polygon>
                  </g>
                </g>
                <g id="bars-inner1-1" mask="url(#mask-4)" fill="#FFFFFF">
                  <g transform="translate(-16.535549, -7.887821)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 4.25117832 109.417874 33.3267215 121.350779 30.699592 65.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 34.2511783 52.4178742 64.3267215 64.3507792 60.699592 8.65661632 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 28.2511783 63.4178742 57.3267215 75.3507792 54.699592 19.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 22.2511783 74.4178742 51.3267215 86.3507792 48.699592 30.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 16.2511783 86.4178742 45.3267215 98.3507792 42.699592 42.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 10.2511783 97.4178742 39.3267215 109.350779 36.699592 53.6566163 6"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="inner2"
                opacity="0.5"
                transform="translate(494.000000, 207.000000)">
                <mask id="mask-6" fill="white">
                  <use xlinkHref="#path-5"></use>
                </mask>
                <g id="inner2-mask"></g>
                <g id="bars-inner2-2" mask="url(#mask-6)" fill="#FFFFFF">
                  <g transform="translate(-97.535549, 28.112179)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 10.2511783 109.417874 39.3267215 121.350779 36.699592 65.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-32"
                      points="69.2946236 4.25117832 117.290733 31.6079465 129.223638 28.9808171 77.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 40.2511783 52.4178742 70.3267215 64.3507792 66.699592 8.65661632 36"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 34.2511783 63.4178742 63.3267215 75.3507792 60.699592 19.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 28.2511783 74.4178742 57.3267215 86.3507792 54.699592 30.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 22.2511783 86.4178742 51.3267215 98.3507792 48.699592 42.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 16.2511783 97.4178742 45.3267215 109.350779 42.699592 53.6566163 12"></polygon>
                  </g>
                </g>
                <g id="bars-inner2-1" mask="url(#mask-6)" fill="#FFFFFF">
                  <g transform="translate(-16.535549, -7.887821)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 4.25117832 109.417874 33.3267215 121.350779 30.699592 65.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 34.2511783 52.4178742 64.3267215 64.3507792 60.699592 8.65661632 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 28.2511783 63.4178742 57.3267215 75.3507792 54.699592 19.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 22.2511783 74.4178742 51.3267215 86.3507792 48.699592 30.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 16.2511783 86.4178742 45.3267215 98.3507792 42.699592 42.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 10.2511783 97.4178742 39.3267215 109.350779 36.699592 53.6566163 6"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="inner1rev"
                opacity="0.5"
                transform="translate(378.000000, 303.000000)">
                <mask id="mask-8" fill="white">
                  <use xlinkHref="#path-7"></use>
                </mask>
                <g id="inner1rev-mask"></g>
                <g id="bars-inner1rev-2" mask="url(#mask-8)" fill="#FFFFFF">
                  <g transform="translate(51.464451, -50.887821)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 10.2511783 109.417874 39.3267215 121.350779 36.699592 65.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-32"
                      points="69.2946236 4.25117832 117.290733 31.6079465 129.223638 28.9808171 77.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 40.2511783 52.4178742 70.3267215 64.3507792 66.699592 8.65661632 36"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 34.2511783 63.4178742 63.3267215 75.3507792 60.699592 19.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 28.2511783 74.4178742 57.3267215 86.3507792 54.699592 30.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 22.2511783 86.4178742 51.3267215 98.3507792 48.699592 42.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 16.2511783 97.4178742 45.3267215 109.350779 42.699592 53.6566163 12"></polygon>
                  </g>
                </g>
                <g id="bars-inner1rev-1" mask="url(#mask-8)" fill="#FFFFFF">
                  <g transform="translate(-16.535549, -7.887821)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 4.25117832 109.417874 33.3267215 121.350779 30.699592 65.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 34.2511783 52.4178742 64.3267215 64.3507792 60.699592 8.65661632 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 28.2511783 63.4178742 57.3267215 75.3507792 54.699592 19.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 22.2511783 74.4178742 51.3267215 86.3507792 48.699592 30.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 16.2511783 86.4178742 45.3267215 98.3507792 42.699592 42.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 10.2511783 97.4178742 39.3267215 109.350779 36.699592 53.6566163 6"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="inner2rev"
                opacity="0.5"
                transform="translate(520.000000, 222.000000)">
                <mask id="mask-10" fill="white">
                  <use xlinkHref="#path-9"></use>
                </mask>
                <g id="inner2rev-mask"></g>
                <g id="bars-inner2rev-2" mask="url(#mask-10)" fill="#FFFFFF">
                  <g transform="translate(48.464451, -51.887821)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 10.2511783 109.417874 39.3267215 121.350779 36.699592 65.6566163 6"></polygon>
                    <polygon
                      id="Path-4-Copy-32"
                      points="69.2946236 4.25117832 117.290733 31.6079465 129.223638 28.9808171 77.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 40.2511783 52.4178742 70.3267215 64.3507792 66.699592 8.65661632 36"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 34.2511783 63.4178742 63.3267215 75.3507792 60.699592 19.6566163 30"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 28.2511783 74.4178742 57.3267215 86.3507792 54.699592 30.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 22.2511783 86.4178742 51.3267215 98.3507792 48.699592 42.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 16.2511783 97.4178742 45.3267215 109.350779 42.699592 53.6566163 12"></polygon>
                  </g>
                </g>
                <g id="bars-inner2rev-1" mask="url(#mask-10)" fill="#FFFFFF">
                  <g transform="translate(-16.535549, -7.887821)">
                    <polygon
                      id="Path-4-Copy-33"
                      points="57.2946236 4.25117832 109.417874 33.3267215 121.350779 30.699592 65.6566163 0"></polygon>
                    <polygon
                      id="Path-4-Copy-38"
                      points="0.29462362 34.2511783 52.4178742 64.3267215 64.3507792 60.699592 8.65661632 30"></polygon>
                    <polygon
                      id="Path-4-Copy-37"
                      points="11.2946236 28.2511783 63.4178742 57.3267215 75.3507792 54.699592 19.6566163 24"></polygon>
                    <polygon
                      id="Path-4-Copy-36"
                      points="22.2946236 22.2511783 74.4178742 51.3267215 86.3507792 48.699592 30.6566163 18"></polygon>
                    <polygon
                      id="Path-4-Copy-35"
                      points="34.2946236 16.2511783 86.4178742 45.3267215 98.3507792 42.699592 42.6566163 12"></polygon>
                    <polygon
                      id="Path-4-Copy-34"
                      points="45.2946236 10.2511783 97.4178742 39.3267215 109.350779 36.699592 53.6566163 6"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="traffic-azure1"
                opacity="0.5"
                transform="translate(541.000000, 150.000000)">
                <mask id="mask-12" fill="white">
                  <use xlinkHref="#path-11"></use>
                </mask>
                <g id="azure1-mask" opacity="0.200000003"></g>
                <g id="bars-azure1-1" mask="url(#mask-12)" fill="#D8D8D8">
                  <g transform="translate(-12.000000, -6.980817)">
                    <polygon
                      id="Path-18"
                      points="30.3045421 -2.84217094e-14 -2.13162821e-14 17.740087 5.81346679 21.235653 36.4692687 2.75381964"></polygon>
                    <polygon
                      id="Path-18-Copy"
                      points="39.0059148 4.70948289 8.70137264 22.4495699 14.5148394 25.9451359 45.1706413 7.46330252"></polygon>
                    <polygon
                      id="Path-18-Copy-3"
                      points="47.5059148 9.70948289 17.2013726 27.4495699 23.0148394 30.9451359 53.6706413 12.4633025"></polygon>
                    <polygon
                      id="Path-18-Copy-2"
                      points="55.9059148 14.4189658 25.6013726 32.1590527 31.4148394 35.6546187 62.0706413 17.1727854"></polygon>
                    <polygon
                      id="Path-18-Copy-5"
                      points="65.5059148 18.7094829 35.2013726 36.4495699 41.0148394 39.9451359 71.6706413 21.4633025"></polygon>
                  </g>
                </g>
                <g id="bars-azure1-2" mask="url(#mask-12)" fill="#D8D8D8">
                  <g transform="translate(32.000000, 17.019183)">
                    <polygon
                      id="Path-18"
                      points="30.3045421 -2.84217094e-14 -2.13162821e-14 17.740087 5.81346679 21.235653 36.4692687 2.75381964"></polygon>
                    <polygon
                      id="Path-18-Copy"
                      points="39.0059148 4.70948289 8.70137264 22.4495699 14.5148394 25.9451359 45.1706413 7.46330252"></polygon>
                    <polygon
                      id="Path-18-Copy-3"
                      points="47.5059148 9.70948289 17.2013726 27.4495699 23.0148394 30.9451359 53.6706413 12.4633025"></polygon>
                    <polygon
                      id="Path-18-Copy-2"
                      points="55.9059148 14.4189658 25.6013726 32.1590527 31.4148394 35.6546187 62.0706413 17.1727854"></polygon>
                    <polygon
                      id="Path-18-Copy-5"
                      points="65.5059148 18.7094829 35.2013726 36.4495699 41.0148394 39.9451359 71.6706413 21.4633025"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="traffic-azure2"
                opacity="0.5"
                transform="translate(641.000000, 117.000000)">
                <mask id="mask-14" fill="white">
                  <use xlinkHref="#path-13"></use>
                </mask>
                <g id="azure2-mask" opacity="0.200000003"></g>
                <g id="bars-azure2-2" mask="url(#mask-14)" fill="#D8D8D8">
                  <g transform="translate(48.000000, 0.435000)">
                    <polygon
                      id="Path-15"
                      points="44.770293 0 78.7730664 18.5841829 73.092358 21.9897805 38.2946236 2.932"></polygon>
                    <polygon
                      id="Path-15-Copy"
                      points="35.4756693 4.58418295 69.4784428 23.1683659 63.7977344 26.5739634 29 7.51618295"></polygon>
                    <polygon
                      id="Path-15-Copy-2"
                      points="26.4756693 9.58418295 60.4784428 28.1683659 54.7977344 31.5739634 20 12.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-3"
                      points="16.4756693 14.5841829 50.4784428 33.1683659 44.7977344 36.5739634 10 17.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-4"
                      points="6.47566933 19.5841829 40.4784428 38.1683659 34.7977344 41.5739634 0 22.5161829"></polygon>
                  </g>
                </g>
                <g id="bars-azure2-1" mask="url(#mask-14)" fill="#D8D8D8">
                  <g transform="translate(0.000000, 25.019183)">
                    <polygon
                      id="Path-15"
                      points="44.770293 0 78.7730664 18.5841829 73.092358 21.9897805 38.2946236 2.932"></polygon>
                    <polygon
                      id="Path-15-Copy"
                      points="35.4756693 4.58418295 69.4784428 23.1683659 63.7977344 26.5739634 29 7.51618295"></polygon>
                    <polygon
                      id="Path-15-Copy-2"
                      points="26.4756693 9.58418295 60.4784428 28.1683659 54.7977344 31.5739634 20 12.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-3"
                      points="16.4756693 14.5841829 50.4784428 33.1683659 44.7977344 36.5739634 10 17.5161829"></polygon>
                    <polygon
                      id="Path-15-Copy-4"
                      points="6.47566933 19.5841829 40.4784428 38.1683659 34.7977344 41.5739634 -4.54747351e-13 22.5161829"></polygon>
                  </g>
                </g>
              </g>
              <g
                id="traffic-user"
                opacity="0.5"
                transform="translate(498.000000, 293.000000)">
                <mask id="mask-16" fill="white">
                  <use xlinkHref="#path-15"></use>
                </mask>
                <g id="user-mask" opacity="0.200000003"></g>
                <g id="bars-user1" mask="url(#mask-16)">
                  <g transform="translate(0.000000, 0.253402)">
                    <polygon
                      id="Path-18"
                      fill="#D8D8D8"
                      points="30.3045421 -2.84217094e-14 -2.13162821e-14 17.740087 5.81346679 21.235653 36.4692687 2.75381964"></polygon>
                    <polygon
                      id="Path-18-Copy"
                      fill="#DE7373"
                      points="39.0059148 4.70948289 8.70137264 22.4495699 14.5148394 25.9451359 45.1706413 7.46330252"></polygon>
                    <polygon
                      id="Path-18-Copy-3"
                      fill="#DE7373"
                      points="47.5059148 9.70948289 17.2013726 27.4495699 23.0148394 30.9451359 53.6706413 12.4633025"></polygon>
                    <polygon
                      id="Path-18-Copy-2"
                      fill="#D8D8D8"
                      points="55.9059148 14.4189658 25.6013726 32.1590527 31.4148394 35.6546187 62.0706413 17.1727854"></polygon>
                    <polygon
                      id="Path-18-Copy-5"
                      fill="#D8D8D8"
                      points="65.5059148 18.7094829 35.2013726 36.4495699 41.0148394 39.9451359 71.6706413 21.4633025"></polygon>
                    <polygon
                      id="Path-18-Copy-4"
                      fill="#DE7373"
                      points="75.7072874 23.4189658 45.4027453 41.1590527 51.2162121 44.6546187 81.872014 26.1727854"></polygon>
                    <polygon
                      id="Path-18-Copy-6"
                      fill="#D8D8D8"
                      points="85.7072874 28.4189658 55.4027453 46.1590527 61.2162121 49.6546187 91.872014 31.1727854"></polygon>
                  </g>
                </g>
                <g id="bars-user2" mask="url(#mask-16)">
                  <g transform="translate(65.000000, 33.253402)">
                    <polygon
                      id="Path-18"
                      fill="#D8D8D8"
                      points="30.3045421 -2.84217094e-14 -2.13162821e-14 17.740087 5.81346679 21.235653 36.4692687 2.75381964"></polygon>
                    <polygon
                      id="Path-18-Copy"
                      fill="#DE7373"
                      points="39.0059148 4.70948289 8.70137264 22.4495699 14.5148394 25.9451359 45.1706413 7.46330252"></polygon>
                    <polygon
                      id="Path-18-Copy-3"
                      fill="#DE7373"
                      points="47.5059148 9.70948289 17.2013726 27.4495699 23.0148394 30.9451359 53.6706413 12.4633025"></polygon>
                    <polygon
                      id="Path-18-Copy-2"
                      fill="#D8D8D8"
                      points="55.9059148 14.4189658 25.6013726 32.1590527 31.4148394 35.6546187 62.0706413 17.1727854"></polygon>
                    <polygon
                      id="Path-18-Copy-5"
                      fill="#D8D8D8"
                      points="65.5059148 18.7094829 35.2013726 36.4495699 41.0148394 39.9451359 71.6706413 21.4633025"></polygon>
                    <polygon
                      id="Path-18-Copy-4"
                      fill="#DE7373"
                      points="75.7072874 23.4189658 45.4027453 41.1590527 51.2162121 44.6546187 81.872014 26.1727854"></polygon>
                    <polygon
                      id="Path-18-Copy-6"
                      fill="#D8D8D8"
                      points="85.7072874 28.4189658 55.4027453 46.1590527 61.2162121 49.6546187 91.872014 31.1727854"></polygon>
                  </g>
                </g>
              </g>
            </g>
            <g
              id="Group-4-Copy-2"
              transform="translate(235.000000, 1670.000000)">
              <g
                id="Group-26-Copy"
                transform="translate(9.000000, 6.000000)"
                fill="#FFFFFF"
                opacity="0.05">
                <g
                  id="pattern-copy-2"
                  transform="translate(406.000000, 0.000000)">
                  <path
                    d="M158.591338,22.4275495 C159.289184,22.0243944 160.420796,22.0243944 161.119766,22.4275495 C161.817612,22.8318275 161.817612,23.4854104 161.119766,23.8896884 C160.420796,24.2928435 159.289184,24.2928435 158.591338,23.8896884 C157.892368,23.4854104 157.892368,22.8318275 158.591338,22.4275495"
                    id="Fill-58-Copy-11"></path>
                  <path
                    d="M164.91016,18.7745604 C165.608006,18.3714053 166.739618,18.3714053 167.438588,18.7745604 C168.136434,19.1777154 168.136434,19.8312983 167.438588,20.2355763 C166.739618,20.6387314 165.608006,20.6387314 164.91016,20.2355763 C164.21119,19.8312983 164.21119,19.1777154 164.91016,18.7745604"
                    id="Fill-60-Copy-11"></path>
                  <path
                    d="M171.230106,15.1204483 C171.927952,14.7172932 173.059564,14.7172932 173.75741,15.1204483 C174.45638,15.5236034 174.45638,16.1771862 173.75741,16.5814643 C173.059564,16.9846193 171.927952,16.9846193 171.230106,16.5814643 C170.531136,16.1771862 170.531136,15.5236034 171.230106,15.1204483"
                    id="Fill-62-Copy-11"></path>
                  <path
                    d="M177.549603,11.4662239 C178.248573,11.0630689 179.379061,11.0630689 180.078031,11.4662239 C180.775877,11.869379 180.775877,12.5229618 180.078031,12.9272399 C179.379061,13.3303949 178.248573,13.3303949 177.549603,12.9272399 C176.851757,12.5229618 176.851757,11.869379 177.549603,11.4662239"
                    id="Fill-64-Copy-11"></path>
                  <path
                    d="M183.868425,7.81211183 C184.567395,7.40895678 185.697883,7.40895678 186.396853,7.81211183 C187.094699,8.21526689 187.094699,8.86884974 186.396853,9.27312779 C185.697883,9.67740584 184.567395,9.67740584 183.868425,9.27312779 C183.170579,8.86884974 183.170579,8.21526689 183.868425,7.81211183"
                    id="Fill-66-Copy-11"></path>
                  <path
                    d="M190.188371,4.15799975 C190.886217,3.7548447 192.017829,3.7548447 192.716799,4.15799975 C193.414645,4.56115481 193.414645,5.21473766 192.716799,5.61901571 C192.017829,6.02329376 190.886217,6.02329376 190.188371,5.61901571 C189.490525,5.21473766 189.490525,4.56115481 190.188371,4.15799975"
                    id="Fill-68-Copy-11"></path>
                  <path
                    d="M196.50843,0.503887671 C197.206276,0.100732614 198.337888,0.100732614 199.036858,0.503887671 C199.734704,0.907042727 199.734704,1.56062558 199.036858,1.96602662 C198.337888,2.36918168 197.206276,2.36918168 196.50843,1.96602662 C195.810584,1.56062558 195.810584,0.907042727 196.50843,0.503887671"
                    id="Fill-70-Copy-11"></path>
                  <path
                    d="M171.230106,22.4275495 C171.927952,22.0243944 173.059564,22.0243944 173.75741,22.4275495 C174.45638,22.8318275 174.45638,23.4854104 173.75741,23.8896884 C173.059564,24.2928435 171.927952,24.2928435 171.230106,23.8896884 C170.531136,23.4854104 170.531136,22.8318275 171.230106,22.4275495"
                    id="Fill-124-Copy-11"></path>
                  <path
                    d="M177.549603,18.7745604 C178.248573,18.3714053 179.379061,18.3714053 180.078031,18.7745604 C180.775877,19.1777154 180.775877,19.8312983 180.078031,20.2355763 C179.379061,20.6387314 178.248573,20.6387314 177.549603,20.2355763 C176.851757,19.8312983 176.851757,19.1777154 177.549603,18.7745604"
                    id="Fill-126-Copy-11"></path>
                  <path
                    d="M183.868425,15.1204483 C184.567395,14.7172932 185.697883,14.7172932 186.396853,15.1204483 C187.094699,15.5236034 187.094699,16.1771862 186.396853,16.5814643 C185.697883,16.9846193 184.567395,16.9846193 183.868425,16.5814643 C183.170579,16.1771862 183.170579,15.5236034 183.868425,15.1204483"
                    id="Fill-128-Copy-11"></path>
                  <path
                    d="M190.188371,11.4662239 C190.887341,11.0630689 192.017829,11.0630689 192.716799,11.4662239 C193.414645,11.869379 193.414645,12.5229618 192.716799,12.9272399 C192.017829,13.3303949 190.886217,13.3303949 190.188371,12.9272399 C189.490525,12.5229618 189.490525,11.869379 190.188371,11.4662239"
                    id="Fill-130-Copy-11"></path>
                  <path
                    d="M196.50843,7.81211183 C197.206276,7.40895678 198.337888,7.40895678 199.036858,7.81211183 C199.734704,8.21526689 199.734704,8.86884974 199.036858,9.27312779 C198.337888,9.67628285 197.206276,9.67628285 196.50843,9.27312779 C195.810584,8.86884974 195.810584,8.21526689 196.50843,7.81211183"
                    id="Fill-132-Copy-11"></path>
                  <path
                    d="M202.827252,4.15799975 C203.525098,3.7548447 204.65671,3.7548447 205.35568,4.15799975 C206.053526,4.56115481 206.053526,5.21473766 205.35568,5.61901571 C204.65671,6.02329376 203.525098,6.02329376 202.827252,5.61901571 C202.129406,5.21473766 202.129406,4.56115481 202.827252,4.15799975"
                    id="Fill-134-Copy-11"></path>
                  <path
                    d="M183.868425,22.4275495 C184.567395,22.0243944 185.697883,22.0243944 186.396853,22.4275495 C187.094699,22.8318275 187.094699,23.4854104 186.396853,23.8896884 C185.697883,24.2928435 184.567395,24.2928435 183.868425,23.8896884 C183.170579,23.4854104 183.170579,22.8318275 183.868425,22.4275495"
                    id="Fill-190-Copy-11"></path>
                  <path
                    d="M190.188371,18.7745604 C190.886217,18.3714053 192.017829,18.3714053 192.716799,18.7745604 C193.414645,19.1777154 193.414645,19.8312983 192.716799,20.2355763 C192.017829,20.6387314 190.886217,20.6387314 190.188371,20.2355763 C189.490525,19.8312983 189.490525,19.1777154 190.188371,18.7745604"
                    id="Fill-192-Copy-11"></path>
                  <path
                    d="M196.50843,15.1204483 C197.206276,14.7172932 198.337888,14.7172932 199.036858,15.1204483 C199.734704,15.5236034 199.734704,16.1771862 199.036858,16.5814643 C198.337888,16.9846193 197.206276,16.9846193 196.50843,16.5814643 C195.810584,16.1771862 195.810584,15.5236034 196.50843,15.1204483"
                    id="Fill-194-Copy-11"></path>
                  <path
                    d="M202.827252,11.4662239 C203.525098,11.0630689 204.65671,11.0630689 205.35568,11.4662239 C206.053526,11.869379 206.053526,12.5229618 205.35568,12.9272399 C204.65671,13.3303949 203.525098,13.3303949 202.827252,12.9272399 C202.129406,12.5229618 202.129406,11.869379 202.827252,11.4662239"
                    id="Fill-196-Copy-11"></path>
                  <path
                    d="M209.147198,7.81211183 C209.845044,7.40895678 210.976656,7.40895678 211.675626,7.81211183 C212.373472,8.21526689 212.373472,8.86884974 211.675626,9.27312779 C210.976656,9.67628285 209.845044,9.67628285 209.147198,9.27312779 C208.449352,8.86884974 208.449352,8.21526689 209.147198,7.81211183"
                    id="Fill-198-Copy-11"></path>
                  <path
                    d="M196.50843,22.4275495 C197.206276,22.0243944 198.337888,22.0243944 199.036858,22.4275495 C199.734704,22.8318275 199.734704,23.4854104 199.036858,23.8896884 C198.337888,24.2928435 197.206276,24.2928435 196.50843,23.8896884 C195.810584,23.4854104 195.810584,22.8318275 196.50843,22.4275495"
                    id="Fill-256-Copy-11"></path>
                  <path
                    d="M202.827252,18.7745604 C203.525098,18.3714053 204.65671,18.3714053 205.35568,18.7745604 C206.053526,19.1777154 206.053526,19.8312983 205.35568,20.2355763 C204.65671,20.6387314 203.525098,20.6387314 202.827252,20.2355763 C202.129406,19.8312983 202.129406,19.1777154 202.827252,18.7745604"
                    id="Fill-258-Copy-11"></path>
                  <path
                    d="M209.147198,15.1204483 C209.845044,14.7172932 210.976656,14.7172932 211.675626,15.1204483 C212.373472,15.5236034 212.373472,16.1771862 211.675626,16.5814643 C210.976656,16.9846193 209.845044,16.9846193 209.147198,16.5814643 C208.449352,16.1771862 208.449352,15.5236034 209.147198,15.1204483"
                    id="Fill-260-Copy-11"></path>
                  <path
                    d="M215.467257,11.4662239 C216.165103,11.0630689 217.296715,11.0630689 217.995685,11.4662239 C218.693531,11.869379 218.693531,12.5229618 217.995685,12.9272399 C217.296715,13.3303949 216.165103,13.3303949 215.467257,12.9272399 C214.769411,12.5229618 214.769411,11.869379 215.467257,11.4662239"
                    id="Fill-262-Copy-11"></path>
                  <path
                    d="M209.147198,22.4275495 C209.845044,22.0243944 210.976656,22.0243944 211.675626,22.4275495 C212.373472,22.8318275 212.373472,23.4854104 211.675626,23.8896884 C210.976656,24.2928435 209.845044,24.2928435 209.147198,23.8896884 C208.449352,23.4854104 208.449352,22.8318275 209.147198,22.4275495"
                    id="Fill-322-Copy-11"></path>
                  <path
                    d="M215.467257,18.7745604 C216.165103,18.3714053 217.296715,18.3714053 217.995685,18.7745604 C218.693531,19.1777154 218.693531,19.8312983 217.995685,20.2355763 C217.296715,20.6387314 216.165103,20.6387314 215.467257,20.2355763 C214.769411,19.8312983 214.769411,19.1777154 215.467257,18.7745604"
                    id="Fill-324-Copy-11"></path>
                  <path
                    d="M221.786079,15.1204483 C222.485049,14.7172932 223.615537,14.7172932 224.314507,15.1204483 C225.012353,15.5236034 225.012353,16.1771862 224.314507,16.5814643 C223.615537,16.9846193 222.485049,16.9846193 221.786079,16.5814643 C221.088233,16.1771862 221.088233,15.5236034 221.786079,15.1204483"
                    id="Fill-326-Copy-11"></path>
                  <path
                    d="M221.786079,22.4275495 C222.485049,22.0243944 223.615537,22.0243944 224.314507,22.4275495 C225.012353,22.8318275 225.012353,23.4854104 224.314507,23.8896884 C223.615537,24.2928435 222.485049,24.2928435 221.786079,23.8896884 C221.088233,23.4854104 221.088233,22.8318275 221.786079,22.4275495"
                    id="Fill-388-Copy-11"></path>
                  <path
                    d="M228.105463,18.7745604 C228.804433,18.3714053 229.936045,18.3714053 230.633891,18.7745604 C231.331737,19.1777154 231.331737,19.8312983 230.633891,20.2355763 C229.936045,20.6387314 228.804433,20.6387314 228.105463,20.2355763 C227.407617,19.8312983 227.407617,19.1777154 228.105463,18.7745604"
                    id="Fill-390-Copy-11"></path>
                  <path
                    d="M234.425522,22.4275495 C235.124492,22.0243944 236.256104,22.0243944 236.95395,22.4275495 C237.651796,22.8318275 237.651796,23.4854104 236.95395,23.8896884 C236.256104,24.2928435 235.124492,24.2928435 234.425522,23.8896884 C233.727676,23.4854104 233.727676,22.8318275 234.425522,22.4275495"
                    id="Fill-454-Copy-11"></path>
                </g>
              </g>
              <path
                d="M613.43595,0.603577707 C619.783182,0.603577707 626.133649,1.99973195 630.977574,4.81228802 L630.977574,4.81228802 L1021.33526,226.791663 C1025.9946,229.497562 1028.39642,233.019595 1028.39642,236.581628 C1028.39642,240.14361 1025.99463,243.664921 1021.3383,246.367779 L1021.3383,246.367779 L433.900791,582.971959 C424.389328,588.496523 417.385789,590.763264 410.504306,590.348578 C402.845298,589.887036 395.353507,586.124393 384.763004,579.973096 L384.763004,579.973096 L7.66263188,363.215184 C3.00466022,360.510626 0.603577707,356.989283 0.603577707,353.427307 C0.603577707,349.865278 3.00468967,346.343214 7.66082147,343.638415 L7.66082147,343.638415 L595.899042,4.80957855 C600.738252,1.99973195 607.088719,0.603577707 613.43595,0.603577707 Z"
                id="--copy"
                stroke="url(#linearGradient-17)"
                strokeWidth="1.20715541"
                opacity="0.300000012"></path>
              <path
                d="M424.564804,149.04269 L638.717007,280.230776 C643.071364,282.898223 648.53267,282.983291 652.967993,280.452755 L856.866316,164.120303 C859.264819,162.751858 860.099846,159.698142 858.731401,157.299639 C858.281917,156.51182 857.626598,155.861106 856.835629,155.417189 L634.058395,30.3876435 C629.798622,27.9969258 624.600763,27.9993524 620.343224,30.3940463 L424.725479,140.421141 C422.318648,141.774887 421.464954,144.823436 422.818699,147.230267 C423.236683,147.973403 423.837758,148.597307 424.564804,149.04269 Z"
                id="Path-13-Copy"
                fillOpacity="0.06"
                fill="#FFFFFF"></path>
            </g>
          </g>
        </g>
      </svg>
      <img
        id="img"
        src="/img/solutions/mcn-hero.png"
        alt="Multi-Cloud Networking"
      />
    </div>
  )
}

export default McnHeroAnim
